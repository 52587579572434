import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
    return(
        <section id="contact" className="section-contact section-padding  section-meta onepage-section">
			<div className="container">
				<div className="section-title-area">
					<h2 className="section-title">Contact Us</h2>									
                </div>
				<div className="row">
					<div className="contact-form col-sm-6 wow slideInUp" style={{visibility: "visible", animationName: "slideInUp"}}>
						<br/>
						<small>
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d522.6319073867505!2d0.04033186253024115!3d51.456865129053526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a9a3f94335ad%3A0x1b32b2a6b56e12cc!2sMulberry%20Place%2C%20Pinnell%20Rd%2C%20London%20SE9%206AR%2C%20UK!5e0!3m2!1sen!2sua!4v1657022128849!5m2!1sen!2sua" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" width="100%" height="450" title="on-map"></iframe>
						</small>
					</div>											
				    <div className="col-sm-6 wow slideInUp" style={{visibility: "visible", animationName: "slideInUp"}}>
						<br/><br/>
				        <div className="address-box">
					        <h3>Situation of Registered Office: England and Wales</h3>
							<div className="address-contact">
							    <span className="fa-stack">
                                    <i class="fa fa-circle fa-stack-2x"></i>
                                    <i class="fa fa-map-marker fa-stack-1x fa-inverse"></i>
                                    <FontAwesomeIcon icon={faMapMarker} size="2x"/>
                                </span>
							    <div className="address-content">Registered Office Address:  12 MULBERRY PLACE PINNELL ROAD LONDON ENGLAND SE9 6AR</div>
						    </div>
					        <div className="address-contact">
							    <span className="fa-stack">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i class="fa fa-envelope-o fa-stack-1x fa-inverse"></i>
                                    <FontAwesomeIcon icon={faEnvelope} size="2x"/>
                                </span>
							    <div className="address-content">
                                    <a href="mailto:kpmanagementcorp@gmail.com">kpmanagementcorp@gmail.com</a>
                                </div>
						    </div>
					    </div>
				    </div>
				</div>
			</div>
		</section>
    );
};

export default Contact;