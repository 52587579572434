import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
const Footer = () => {
    const dateNow = new Date();    
    return(
        <footer id="colophon" className="site-footer" role="contentinfo">
            <div classNme="site-info">
			    <div class="container">
				    <div className="btt">
					    <a className="back-to-top" href="#page" title="Back To Top">                            
                            <i className="wow flash" data-wow-duration="2s" style={{visibility: "visible", animationDuration: "2s", animationName: "flash"}}>
                                <FontAwesomeIcon icon={faAngleDoubleUp} />
                            </i>
                        </a>
				    </div>
				    Copyright © {dateNow.getFullYear()} KP MANAGEMENT CORPORATION LTD
			    </div>
		    </div>
	    </footer>
    );
};

export default Footer;