const About = () => {
    return(
        <section id="About" className="section-about section-padding onepage-section">		
			<div className="container">
				<div className="section-title-area">
					<h2 className="section-title">About Us</h2>									
                </div>
				<div className="row">
					<div className="col-lg-12 col-sm-12  wow slideInUp" style={{visibility: "visible", animationNname: "slideInUp"}}>
						<h3>About Company</h3>																
                        <p>Сompany N: 13090844</p>
                        <p>Company Name in full: KP MANAGEMENT CORPORATION&nbsp; LTD</p>
                        <p>Company Type: Private company limited by shares</p>
                        <p>Situation of Registered Office: England and Wales</p>
                        <p>Proposed Registered Office Address:&nbsp; 12 MULBERRY PLACE PINNELL ROAD LONDON ENGLAND SE9 6AR</p>
                        <p>Director:&nbsp; MARIIA CHEREDNYK</p>
				    </div>
				</div>
			</div>
		</section>
    );
};

export default About;